import React, { useRef } from 'react';
import { useEnvironment, useExperiments } from '@wix/yoshi-flow-editor';
import { useSettings } from '@wix/tpa-settings/react';

import {
  Text,
  TextTypography as TYPOGRAPHY,
  HeroImage,
  ImageFocalPointPresets,
  ImageLoadingBehaviorOptions,
  ImageResizeOptions,
} from 'wix-ui-tpa';

import { HeaderViewModel } from '../../../../service-page-view-model/header-view-model/headerViewModel';
import { ImagePositionOptions, MainComponents } from '../../types';
import { RenderLocationProvider } from '../useRenderLocation';
import { useSizeListener } from '../useSizeListener';
import { useDimensions } from '../useDimensions';
import { st, classes } from './Header.st.css';

import settingsParams from '../../settingsParams';
import BookButton from '../BookButton/BookButton';

type HeaderProps = {
  viewModel: HeaderViewModel;
  className?: string;
};

const imagePositionToFocalPoint = {
  [ImagePositionOptions.BOTTOM]: ImageFocalPointPresets.bottomCenter,
  [ImagePositionOptions.BOTTOM_LEFT]: ImageFocalPointPresets.bottomLeft,
  [ImagePositionOptions.BOTTOM_RIGHT]: ImageFocalPointPresets.bottomRight,
  [ImagePositionOptions.LEFT]: ImageFocalPointPresets.centerLeft,
  [ImagePositionOptions.RIGHT]: ImageFocalPointPresets.centerRight,
  [ImagePositionOptions.TOP]: ImageFocalPointPresets.centerTop,
  [ImagePositionOptions.TOP_LEFT]: ImageFocalPointPresets.topLeft,
  [ImagePositionOptions.TOP_RIGHT]: ImageFocalPointPresets.topRight,
  [ImagePositionOptions.MIDDLE]: ImageFocalPointPresets.center,
};

const Header: React.FC<HeaderProps> = ({ viewModel, className }) => {
  const settings = useSettings();
  const { experiments } = useExperiments();
  const headerRef = useRef<HTMLDivElement>(null);
  const { isMobile, isRTL } = useEnvironment();
  const headerDimensions = useSizeListener(headerRef);
  const widgetDimensions = useDimensions();

  const isAddBackgroundColorToHeaderImageEnabled = experiments.enabled(
    'specs.bookings.addBackgroundColorToHeaderImage',
  );

  const serviceImage = () => {
    return settings.get(settingsParams.headerImageVisibility) &&
      viewModel?.image ? (
      <div className={classes.media}>
        {headerDimensions.width && headerDimensions.height && (
          <HeroImage
            key={`${JSON.stringify(
              settings.get(settingsParams.headerImagePosition),
            )} ${viewModel?.image?.relativeUri} ${headerDimensions?.height} ${
              widgetDimensions.width
            }`}
            sourceHeight={viewModel.image.height}
            sourceWidth={viewModel.image.width}
            isInFirstFold={widgetDimensions.height > headerDimensions.height}
            resize={ImageResizeOptions.cover}
            src={viewModel?.image?.relativeUri || ''}
            data-hook="header-img"
            width={widgetDimensions.width}
            height={headerDimensions.height}
            alt={viewModel?.image?.altText}
            loadingBehavior={ImageLoadingBehaviorOptions.blur}
            focalPoint={
              imagePositionToFocalPoint[
                settings.get(settingsParams.headerImagePosition)
              ]
            }
            fluid
            className={classes.image}
            isSEOBot={viewModel?.isSEO}
          />
        )}
      </div>
    ) : null;
  };

  const serviceTitle = () =>
    settings.get(settingsParams.headerTitleVisibility) ? (
      <Text
        data-hook="header-title"
        className={st(classes.title, { isRTL }, className)}
        typography={TYPOGRAPHY.largeTitle}
        tagName={settings.get(settingsParams.headerTitleHtmlTag)}
      >
        {viewModel?.title}
      </Text>
    ) : null;

  const bookButton = () =>
    settings.get(settingsParams.headerBookButtonVisibility) &&
    viewModel.isBookable ? (
      <BookButton dataHook="header-book-button" renderedAt="HEADER" />
    ) : null;

  return (
    <RenderLocationProvider value={MainComponents.HEADER}>
      <header
        className={st(
          classes.root,
          {
            alignment: settings.get(settingsParams.headerTitleAlignment),
            isMobile,
            isRTL,
            isAddBackgroundColorToHeaderImageEnabled,
          },
          className,
        )}
        aria-label={viewModel?.title}
        data-hook="header-wrapper"
        ref={headerRef}
      >
        {serviceImage()}
        {serviceTitle()}
        {bookButton()}
      </header>
    </RenderLocationProvider>
  );
};

export default Header;
