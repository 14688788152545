import React from 'react';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import { Text, TextTypography as TYPOGRAPHY } from 'wix-ui-tpa';
import { classes, st } from './EmptyState.st.css';

export default ({
  isRedirectingToCalendar = false,
  dataHook = 'empty-state',
}) => {
  const { t } = useTranslation();
  const { isMobile } = useEnvironment();

  return (
    <>
      {isRedirectingToCalendar ? (
        <div data-hook="empty-state-div"></div>
      ) : (
        <div
          className={st(classes.root, { isMobile })}
          data-hook={dataHook}
          role="status"
        >
          <Text
            data-hook="empty-state-title"
            className={classes.title}
            tagName="h2"
            typography={TYPOGRAPHY.largeTitle}
          >
            {t('empty-state.title')}
          </Text>
          <Text data-hook="empty-state-text" className={classes.text}>
            {t('empty-state.text')}
          </Text>
        </div>
      )}
    </>
  );
};
